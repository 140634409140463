<template>
  <b-container fluid class="maccabi-selection p-4 bg-dark" :class="`maccabi-count-${destinations.length}`">
    <b-row class="desktop-row">
      <b-col v-for="(dest, inx) in destinations" :key="inx" class="one-thumb">
        <router-link :to="`${dest.url}`">
          <b-img class="dest-image" thumbnail fluid :src="dest.images[0]" rounded alt="Image 1"></b-img>
          <p class="text">
            {{ (dest.dest === 'BDV') ? 'מונטנגרו' : ( (dest.dest === 'BUH') ? 'רומניה'  : dest.name[lang]) }}
          </p>
          <b-button class="order-button" size="sm" variant="primary">{{ $t('home.book-now.book-now') }}</b-button>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol, BImg, BButton } from 'bootstrap-vue';
import { mapGetters } from 'vuex/dist/vuex.common.js';

export default {
  name: 'maccabi-deal ',
  props: {
    deals: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BContainer, BRow, BCol, BImg, BButton,
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
    destinations() {
      const { deals } = this;
      const dets = deals.map((deal) => ({
        dest: deal.destinationCode,
        images: deal.destImageUrls,
        url: deal.selectedPackageUrl,
        name: deal.destinationName,
      }));
      const uniqueDestList = dets.reduce((acc, current) => {
        const duplicate = acc.find((item) => item.dest === current.dest);
        if (!duplicate) {
          acc.push(current);
        }
        return acc;
      }, []);
      return uniqueDestList;
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
  .maccabi-count-3 {
    width: 70%;
  }
  .maccabi-count-2 {
    width: 50%;
  }
  .maccabi-count-1 {
    width: 30%;
  }
  .maccabi-selection .dest-image {
    width: 100%;
    height: 100%;
  }

  .maccabi-selection .one-thumb {
    position: relative;
  }
  .maccabi-selection .one-thumb .text{
    position: absolute;
    color: white;
    font-size: 3rem;
    font-weight: 800;
    bottom: 0;
    padding-right: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
    text-shadow: 1px 2px 10px black;
  }
  .maccabi-selection .one-thumb .order-button{
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }

  @media (max-width:479px){
    .maccabi-selection {
      width: 100%;
    }
    .maccabi-selection .one-thumb .text{
      padding-right: 10px;
      font-size: 1.5rem;
    }
    .maccabi-selection .one-thumb{
      margin-bottom: 10px;
    }
    .maccabi-selection .desktop-row{
      display: block;
    }
  }
</style>
